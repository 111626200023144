<template>
    <div>
        <v-card
            class="pa-2"
            color="primary"
            elevation="4"
            dark>
            <div class="mt-2 text-center">
              מוקדש ל{{ type }}
            </div>

            <div class="mt-2 text-center font-weight-bold">{{ name }}</div>


            <div class="mt-2 text-center caption">לשיתוף הקדשה 
                <strong>ל{{ type }} {{ name }}</strong>
            </div>
            <div>
                <ShareDedicated :shareData="share"/>
            </div>
          </v-card>
    </div>
</template>

<script>
import ShareDedicated from './App/ShareDedicated.vue'

export default {
    props: ['prayer'],
    data() {
        return {
            type: '',
            name: '',
            share: {
                title: '',
                description: '',
                url: ''
            }
        }
    },
    methods: {
        initData() {
            this.type = this.getType()
            this.name = this.getName()
            this.setShareData()
        },
        getType() {
            switch(this.$route.params.type) {
                case '1':
                    return 'עילוי נשמת'
                case '2':
                    return 'רפואת'
                case '3':
                    return 'הצלחת'
                default:
                    return ''

            }
        },
        getName() {
            return this.$route.params.name
        },
        setShareData() {
            this.share.title = this.prayer.name
            this.share.description = 'מוקדש ל' + this.type + ' ' + this.name
            this.share.url = 'https://AmIsraeLive.com' + this.$router.currentRoute.path
        }
    },
    components: { 
        ShareDedicated 
    },
    created() {
        this.initData()
    }
}

</script>