<template>
    <div class="text-center">
        <v-row class="mx-auto mt-4 mb-4"
            justify="center">
            
            <ShareNetwork
                v-for="platform in platforms"
                :network="platform.network"
                :key="platform.network"
                :url="share.url"
                :title="share.title"
                :description="share.description"
                :quote="share.quote"
                :hashtags="share.hashtags"
                :twitterUser="share.twitterUser">

                <span>
                    <v-btn class="ml-1 mr-1" x-small fab dark :color="platform.color">
                        <v-icon small dark color="white">{{ platform.icon }}</v-icon>
                    </v-btn>
                </span>  
            </ShareNetwork>
        </v-row>
    </div>
</template>

<script>

export default {
    props: ['shareData'],
    data() {
        return {
            platforms: [
                { network: 'email', name: 'אימייל', icon: 'mdi-email', color: '#333333' },
                { network: 'whatsapp', name: 'ווטסאפ', icon: 'mdi-whatsapp ', color: '#25d366' },
                { network: 'facebook', name: 'פייסבוק', icon: 'mdi-facebook', color: '#1877f2' },
                { network: 'sms', name: 'סמס', icon: 'mdi-message-processing', color: '#EF6C00' },
                { network: 'telegram', name: 'טלגרם', icon: 'mdi-telegram', color: '#0088cc' },
                { network: 'twitter', name: 'טוויטר', icon: 'mdi-twitter', color: '#1da1f2' },
            ],
        }
    },
    computed: {
        share: function() {
            let share = {
                url: this.shareData.url,
                title: this.shareData.title,
                description: this.shareData.description,
                quote: '',
                hashtags: '',
                twitterUser: ''
            }
            return share
        }
    },
    methods: {
       
    }
}

</script>


<style scoped>

 *, ::before, ::after{
  text-decoration: none !important;
}

</style>